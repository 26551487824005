
import { defineComponent } from "vue";

// Components
import AppHeaderback from "../components/AppHeaderBack.vue";
import AppCoverdesign from "@/components/AppCoverdesign.vue";
import AppContact from "@/components/AppContact.vue";

export default defineComponent({
  name: "AppCoverdesignView",
  components: {
    AppHeaderback,
    AppCoverdesign,
    AppContact,
  },
});
