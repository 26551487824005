import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/Background240.webp'
import _imports_1 from '@/assets/Logo_zentral.webp'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_parallax = _resolveComponent("v-parallax")!

  return (_openBlock(), _createBlock(_component_v_parallax, {
    src: _imports_0,
    height: "100vh"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, {
        id: _ctx.id,
        tag: "section"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            "no-gutters": "",
            style: {"height":"100vh"},
            align: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                align: "center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_img, {
                    src: _imports_1,
                    alt: "Logo",
                    class: "logo",
                    width: 360,
                    "aspect-ratio": "1/1",
                    cover: ""
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["id"])
    ]),
    _: 1
  }))
}