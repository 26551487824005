
import { defineComponent } from "vue";
import AppAboutItem from "@/components/AppAboutItem.vue";
import { AboutItem } from "@/types/AboutItem";

export default defineComponent({
  name: "AppAbout",
  components: {
    AppAboutItem,
  },
  props: {
    currentSection: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  watch: {
    currentSection: function (newSectionId) {
      if (newSectionId === this.id) {
        this.isIntersecting = true;
      }
    },
  },
  data() {
    return {
      isIntersecting: false,
      aboutItems: [
        {
          type: "Weiterbildung",
          transitionClass: "fade2",
          title: "3D und Motion - Design",
          subtitle: "alfatraining",
          date: "2023",
        },
        {
          type: "Weiterbildung",
          transitionClass: "fade2",
          title: "Grafik und Design",
          subtitle: "IBB Dresden",
          date: "2018",
        },
        {
          type: "Studium",
          transitionClass: "fade3",
          title: "Bachelor of Arts",
          subtitle: "HTW Dresden / Fakultät Gestaltung",
          date: "2014 - 2017",
        },
        {
          type: "Fachabitur",
          transitionClass: "fade4",
          title: "Gestaltung",
          subtitle: "BSZ für Dienstleistungen und Gestaltung Dresden",
          date: "2011 - 2013",
        },
      ] as AboutItem[],
    };
  },
});
