import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/header.webp'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_app_bar, {
      "scroll-behavior": "elevate",
      image: "@/assets/header.webp",
      fixed: "",
      color: "primary"
    }, {
      image: _withCtx(() => [
        _createVNode(_component_v_img, { src: _imports_0 })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar_nav_icon, {
          variant: "plain",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.drawer = !_ctx.drawer), ["stop"])),
          class: "d-md-none"
        }),
        _createVNode(_component_v_container, { class: "d-none d-sm-flex justify-space-evenly" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, i) => {
              return (_openBlock(), _createBlock(_component_v_btn, {
                key: i,
                "data-text": item.id,
                variant: "plain",
                size: "x-large",
                onClick: ($event: any) => (_ctx.scrollToSection(item.id)),
                ripple: false,
                class: "menuitem"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.text), 1)
                ]),
                _: 2
              }, 1032, ["data-text", "onClick"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_navigation_drawer, {
      modelValue: _ctx.drawer,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.drawer) = $event)),
      location: "bottom",
      temporary: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_list, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
              return (_openBlock(), _createBlock(_component_v_list_item, {
                key: item.id,
                onClick: ($event: any) => (_ctx.scrollToSection(item.id))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.text), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}