import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_headerback = _resolveComponent("app-headerback")!
  const _component_AppCatalog = _resolveComponent("AppCatalog")!
  const _component_AppContact = _resolveComponent("AppContact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_headerback),
    _createVNode(_component_AppCatalog),
    _createVNode(_component_AppContact, {
      currentSection: "contact",
      id: "contact",
      isLandingpage: true,
      title: "Möchten Sie Ihren Katalog in neuem Glanz erstrahlen lassen?",
      callToAction: "Dann lassen Sie mich Ihren Katalog gestalten und Ihre Kunden überzeugen!",
      text: "Ich helfe Ihnen dabei, einen Katalog zu erstellen, der Ihre Produkte und Dienstleistungen in einem ansprechenden und informativen Format präsentiert. Kontaktieren Sie mich noch heute, um ein kostenloses Beratungsgespräch zu vereinbaren.",
      subject: "Grafikdesign"
    })
  ], 64))
}