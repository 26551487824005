import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/paralax.png'


const _hoisted_1 = { class: "d-flex flex-column fill-height justify-center align-center text-white text-h2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_parallax = _resolveComponent("v-parallax")!

  return (_openBlock(), _createBlock(_component_v_parallax, {
    height: "400",
    src: _imports_0
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title.toUpperCase()), 1)
    ]),
    _: 1
  }))
}