import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_headerback = _resolveComponent("app-headerback")!
  const _component_AppPortrait = _resolveComponent("AppPortrait")!
  const _component_AppContact = _resolveComponent("AppContact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_headerback),
    _createVNode(_component_AppPortrait),
    _createVNode(_component_AppContact, {
      currentSection: "contact",
      id: "contact",
      isLandingpage: true,
      title: "Wünschst du dir ein einzigartiges Portrait von dir oder einem geliebten Menschen?",
      callToAction: "Kontaktiere mich noch heute und lass uns dein ganz persönliches Portraitprojekt besprechen!",
      text: "Ob du ein Geschenk für einen geliebten Menschen suchst, ein Portrait für deine Website oder ein persönliches Kunstwerk für dein Zuhause, ich bin mir sicher, dass ich das perfekte Portrait für dich anfertigen kann.",
      subject: "Illustration"
    })
  ], 64))
}