import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/header.webp'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, {
    "scroll-behavior": "elevate",
    image: "@/assets/header.webp",
    fixed: "",
    color: "primary"
  }, {
    image: _withCtx(() => [
      _createVNode(_component_v_img, { src: _imports_0 })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_container, { class: "d-sm-flex" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                variant: "plain",
                size: "x-large",
                ripple: false,
                class: "menuitem active"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    start: "",
                    icon: "mdi-arrow-left-bold-outline"
                  }),
                  _createTextVNode(" Zurück")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}