import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_headerback = _resolveComponent("app-headerback")!
  const _component_AppFlyer = _resolveComponent("AppFlyer")!
  const _component_AppContact = _resolveComponent("AppContact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_headerback),
    _createVNode(_component_AppFlyer),
    _createVNode(_component_AppContact, {
      currentSection: "contact",
      id: "contact",
      isLandingpage: true,
      title: "Möchten Sie einen Flyer, der Ihre Zielgruppe überzeugt?",
      callToAction: "Dann kontaktieren Sie mich jetzt und ich erstelle Ihren Flyer nach Ihren Wünschen!",
      text: "Ihr Flyer ist das Aushängeschild Ihres Unternehmens oder Ihrer Dienstleistung. Er ist der erste Eindruck, den Ihre Zielgruppe von Ihnen bekommt. Daher ist es wichtig, dass Ihr Flyer ansprechend und informativ ist. Ich gestalte Ihren Flyer so, dass er die gewünschte Wirkung erzielt.",
      subject: "Grafikdesign"
    })
  ], 64))
}