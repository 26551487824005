import { createApp } from "vue";
// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, mdi } from "vuetify/iconsets/mdi"; // For Material Design Icons
import "@mdi/font/css/materialdesignicons.css"; // Ensure the CSS is loade
import App from "./App.vue";
import router from "./router";
import { loadFonts } from "./plugins/webfontloader";
import { createHead } from "@unhead/vue";

loadFonts();
const head = createHead();
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});
createApp(App).use(vuetify).use(router).use(head).mount("#app");
