
import { defineComponent } from "vue";
import { AboutItem } from "@/types/AboutItem";

export default defineComponent({
  name: "AppAboutItem",
  props: {
    aboutItem: {
      type: Object as () => AboutItem,
      required: true,
    },
  },
});
