import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (!_ctx.isMobile)
    ? (_openBlock(), _createBlock(_component_v_btn, {
        key: 0,
        size: "large",
        onClick: _ctx.scrollToSection,
        fab: "",
        class: "scroll-fab",
        icon: _ctx.icon
      }, null, 8, ["onClick", "icon"]))
    : _createCommentVNode("", true)
}