import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_headerback = _resolveComponent("app-headerback")!
  const _component_AppDigitaleIllustration = _resolveComponent("AppDigitaleIllustration")!
  const _component_AppContact = _resolveComponent("AppContact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_headerback),
    _createVNode(_component_AppDigitaleIllustration),
    _createVNode(_component_AppContact, {
      currentSection: "contact",
      id: "contact",
      isLandingpage: true,
      title: "Bist du auf der Suche nach einzigartigen digitalen Illustrationen?",
      callToAction: "Jetzt unverbindlich anfragen und deine Idee in die Tat umsetzen!",
      text: "Wenn du auf der Suche nach digitalen Illustrationen bist, die deine Botschaft vermitteln, deine Produkte oder Dienstleistungen bewerben oder einfach nur deine Augen erfreuen, dann bin ich der richtige Ansprechpartnerin für dich.",
      subject: "Illustration"
    })
  ], 64))
}