
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppStart",
  props: {
    currentSection: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  watch: {
    currentSection: function (newSectionId) {
      if (newSectionId === this.id) {
        this.isIntersecting = true;
      } else {
        this.isIntersecting = false;
      }
    },
  },
  data() {
    return {
      isIntersecting: true,
    };
  },
});
