import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "my-5" }
const _hoisted_3 = { class: "text-h4 my-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_App_parallax = _resolveComponent("App-parallax")!
  const _component_AppSocialmedia = _resolveComponent("AppSocialmedia")!
  const _component_AppContactForm = _resolveComponent("AppContactForm")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("section", { id: _ctx.id }, [
    (!_ctx.isLandingpage)
      ? (_openBlock(), _createBlock(_component_App_parallax, {
          key: 0,
          title: _ctx.pageTitle
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.callToAction), 1),
                  _createVNode(_component_AppSocialmedia, { isIntersecting: _ctx.isIntersecting }, null, 8, ["isIntersecting"]),
                  _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
                ]),
                _createVNode(_component_AppContactForm)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 8, _hoisted_1))
}