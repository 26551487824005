import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_headerback = _resolveComponent("app-headerback")!
  const _component_AppFirmenlogo = _resolveComponent("AppFirmenlogo")!
  const _component_AppContact = _resolveComponent("AppContact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_headerback),
    _createVNode(_component_AppFirmenlogo),
    _createVNode(_component_AppContact, {
      currentSection: "contact",
      id: "contact",
      isLandingpage: true,
      title: "Möchten Sie ein Logo, das Ihre Marke widerspiegelt?",
      callToAction: "Kontaktieren Sie mich noch heute für ein kostenloses Beratungsgespräch.",
      text: "Wenn Sie ein Logo suchen, das professionell, einprägsam und ansprechend ist, dann sind Sie bei mir an der richtigen Adresse. Ich bin ein erfahrener Grafikdesigner, der sich auf die Erstellung von Logos für Unternehmen aus allen Branchen spezialisiert hat. Ich verstehe, dass jedes Unternehmen einzigartig ist, und ich arbeite eng mit Ihnen zusammen, um ein Logo zu erstellen, das Ihre Marke und Ihre Werte widerspiegelt. Ich biete Ihnen eine Vielzahl von Optionen, damit Sie das perfekte Logo für Ihre Bedürfnisse finden können.",
      subject: "Grafikdesign"
    })
  ], 64))
}