
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    type: {
      type: String,
      required: true,
    },
    errMessage: String,
  },
  data() {
    return {
      color: "",
      icon: "",
      alertText: "",
    };
  },
  watch: {
    type: {
      immediate: true,
      /**
       * @param newVal = new status of alert
       */
      handler(newVal: string) {
        switch (newVal) {
          case "success":
            this.color = "success";
            this.icon = "$success";
            this.alertText = "Fertig, E-Mail wurde gesendet!";
            break;
          case "error":
            this.color = "error";
            this.icon = "$error";
            this.alertText = `Unerwarteter Fehler: ${this.errMessage}`;
            break;
          case "info":
            this.color = "info";
            this.icon = "$info";
            this.alertText = "E-Mail wird gerade gesendet...";
            break;
          default:
            break;
        }
      },
    },
  },
});
