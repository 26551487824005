import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_headerback = _resolveComponent("app-headerback")!
  const _component_AppArtCreate = _resolveComponent("AppArtCreate")!
  const _component_AppContact = _resolveComponent("AppContact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_headerback),
    _createVNode(_component_AppArtCreate),
    _createVNode(_component_AppContact, {
      currentSection: "contact",
      id: "contact",
      isLandingpage: true,
      title: "Bist du auf der Suche nach einem einzigartigen Kunstwerk für dein Zuhause?",
      callToAction: "Dann entdecke jetzt meine Kunstwerke und finde das perfekte Bild für dein Zuhause!",
      text: "Jetzt unverbindlich anfragen und dein Zuhause verschönern!",
      subject: "Art",
      artItem: "individuellesKunstwerk"
    })
  ], 64))
}