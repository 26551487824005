import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, { name: "slide-fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_v_alert, {
          color: _ctx.color,
          icon: _ctx.icon,
          elevation: "3",
          text: _ctx.alertText
        }, null, 8, ["color", "icon", "text"]), [
          [_vShow, _ctx.alertText.length > 0]
        ])
      ]),
      _: 1
    })
  ]))
}