import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70c8ce5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-h6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_list, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_list_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_item_title, null, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_1, [
                _createVNode(_component_v_icon, {
                  icon: "mdi-check-outline",
                  size: "x-small"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.aboutItem.title), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_v_list_item_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.aboutItem.type), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_list_item_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.aboutItem.subtitle), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_list_item_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.aboutItem.date), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}