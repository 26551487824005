
import { defineComponent } from "vue";
import AppParallax from "./AppParallax.vue";
import AppServiceCard from "./AppServiceCard.vue";

export default defineComponent({
  name: "AppService",
  components: {
    AppParallax,
    AppServiceCard,
  },
  props: {
    currentSection: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  watch: {
    currentSection: function (newSectionId) {
      if (newSectionId === this.id) {
        this.isIntersecting = true;
      }
    },
  },
  data() {
    return {
      pageTitle: "LEISTUNGEN",
      cards: [
        {
          img: "graphic.webp",
          title: "Grafikdesign",
          text: "Corporatedesign, Firmenlogo, Visitenkarten, Flyer, Broschüren, Banner, Katalogen, Coverdesign",
          transitionClass: "slide-fade",
        },
        {
          img: "illustration.webp",
          title: "Illustration",
          text: "Portraitillustrationen, Buchillustrationen, digitale Illustrationen",
          transitionClass: "slide-fade2",
        },
        {
          img: "art.webp",
          title: "Art",
          text: "Kaufen, Ausstellen, Erschaffen",
          transitionClass: "slide-fade3",
        },
      ],
      isIntersecting: false,
    };
  },
});
