
import { defineComponent } from "vue";

// Components
import AppHeaderback from "@/components/AppHeaderBack.vue";
import AppArtCreate from "@/components/AppArtCreate.vue";
import AppContact from "@/components/AppContact.vue";

export default defineComponent({
  name: "AppArtCreateView",
  components: {
    AppHeaderback,
    AppArtCreate,
    AppContact,
  },
});
