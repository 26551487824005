import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_headerback = _resolveComponent("app-headerback")!
  const _component_AppBanner = _resolveComponent("AppBanner")!
  const _component_AppContact = _resolveComponent("AppContact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_headerback),
    _createVNode(_component_AppBanner),
    _createVNode(_component_AppContact, {
      currentSection: "contact",
      id: "contact",
      isLandingpage: true,
      title: "Sie möchten mit einem Banner Aufmerksamkeit erregen und Ihre Zielgruppe ansprechen?",
      callToAction: "Dann kontaktieren Sie mich noch heute und lassen Sie sich ein unverbindliches Angebot erstellen!",
      text: "Ich bin ein erfahrener Designer und kenne mich mit den neuesten Trends und Techniken aus. Ich arbeite eng mit Ihnen zusammen, um Ihre Ziele zu verstehen und Banner zu erstellen, die perfekt zu Ihnen passen.",
      subject: "Grafikdesign"
    })
  ], 64))
}