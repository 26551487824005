import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Transition as _Transition, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63c8464c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"list-style-type":"none"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_Transition, { name: _ctx.transitionClass }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: "service-card",
        variant: "elevated"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_img, {
            src: _ctx.imagePath,
            height: "260"
          }, null, 8, ["src"]),
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createElementVNode("ul", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.convertedText, (item) => {
                  return (_openBlock(), _createElementBlock("li", { key: item }, [
                    _createVNode(_component_router_link, {
                      to: _ctx.getLink(item)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          variant: "plain",
                          "prepend-icon": "mdi-arrow-right-bold-outline"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, { class: "service-card-actions" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollToSection(_ctx.title)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("referenzen")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["name"]))
}