import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_headerback = _resolveComponent("app-headerback")!
  const _component_AppCorporateDesign = _resolveComponent("AppCorporateDesign")!
  const _component_AppContact = _resolveComponent("AppContact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_headerback),
    _createVNode(_component_AppCorporateDesign),
    _createVNode(_component_AppContact, {
      currentSection: "contact",
      id: "contact",
      isLandingpage: true,
      title: "Möchten Sie ein Corporate Design, das Ihre Marke einzigartig macht?",
      callToAction: "Dann kontaktieren Sie mich jetzt und lassen Sie sich ein unverbindliches Angebot erstellen!",
      text: "Ich erstelle für Sie ein Corporate Design, das Ihre Marke einzigartig macht und Ihre Ziele unterstützt. Ich berücksichtige dabei Ihre Unternehmensphilosophie, Ihre Zielgruppe und Ihre Marktposition.",
      subject: "Grafikdesign"
    })
  ], 64))
}