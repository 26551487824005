import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf836c52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-h4 text-center mt-5" }
const _hoisted_2 = { class: "my-5" }
const _hoisted_3 = { class: "text-overline text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_carousel_item = _resolveComponent("v-carousel-item")!
  const _component_v_carousel = _resolveComponent("v-carousel")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { id: _ctx.title }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "my-5" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.text), 1),
              _createVNode(_Transition, {
                name: "slide-fade",
                css: !_ctx.isMobile
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_v_carousel, {
                    "hide-delimiter-background": "",
                    "show-arrows": "hover"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carouselItems, (item, index) => {
                        return (_openBlock(), _createBlock(_component_v_carousel_item, { key: index }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_img, {
                              src: _ctx.imagePath(item.src),
                              alt: item.title,
                              class: "carouselImg my-15 my-sm-0"
                            }, null, 8, ["src", "alt"]),
                            _createElementVNode("div", _hoisted_3, _toDisplayString(item.title), 1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, _ctx.isIntersecting || _ctx.isMobile]
                  ])
                ]),
                _: 1
              }, 8, ["css"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["id"]))
}