
import { defineComponent } from "vue";

// Components
import AppHeaderback from "../components/AppHeaderBack.vue";
import AppDigitaleIllustration from "@/components/AppDigitaleIllustration.vue";
import AppContact from "@/components/AppContact.vue";

export default defineComponent({
  name: "AppDigitaleIllustrationView",
  components: {
    AppHeaderback,
    AppDigitaleIllustration,
    AppContact,
  },
});
