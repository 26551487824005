
import { defineComponent } from "vue";
import { smoothScrollTo } from "@/utilities/smoothScrollTo";

interface MenuItem {
  id: string;
  text: string;
}

export default defineComponent({
  name: "AppHeader",
  data() {
    return {
      drawer: false,
      menuItems: [
        { id: "home", text: "HOME" },
        { id: "about", text: "ÜBER MICH" },
        { id: "service", text: "LEISTUNGEN" },
        { id: "portfolio", text: "PORTFOLIO" },
        { id: "contact", text: "KONTAKT" },
      ] as MenuItem[],
    };
  },
  methods: {
    scrollToSection(sectionId: string) {
      const section = document.getElementById(sectionId);
      if (section) {
        smoothScrollTo(section);
        setTimeout(() => {
          if (this.needsScrolling(section)) {
            smoothScrollTo(section);
          }
        }, 1000);
      }
    },

    needsScrolling(section: HTMLElement): boolean {
      const targetPosition = section.offsetTop;
      const distanceToScroll = targetPosition - window.pageYOffset;
      return distanceToScroll > 1;
    },
  },
});
