
import { defineComponent } from "vue";

// Components
import AppHeaderback from "../components/AppHeaderBack.vue";
import AppCorporateDesign from "@/components/AppCorporateDesign.vue";
import AppContact from "@/components/AppContact.vue";

export default defineComponent({
  name: "AppCorporateDesignView",
  components: {
    AppHeaderback,
    AppCorporateDesign,
    AppContact,
  },
});
