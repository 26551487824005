
import { defineComponent } from "vue";
export default defineComponent({
  name: "AppFooter",
  computed: {
    siteTitle() {
      return "Elena Elodia";
    },
    year() {
      return new Date().getFullYear();
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
});
