
import { defineComponent } from "vue";

// Components
import AppHeaderback from "../components/AppHeaderBack.vue";
import AppPortrait from "@/components/AppPortrait.vue";
import AppContact from "@/components/AppContact.vue";

export default defineComponent({
  name: "AppPortraitView",
  components: {
    AppHeaderback,
    AppPortrait,
    AppContact,
  },
});
