import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_headerback = _resolveComponent("app-headerback")!
  const _component_AppVisitcard = _resolveComponent("AppVisitcard")!
  const _component_AppContact = _resolveComponent("AppContact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_headerback),
    _createVNode(_component_AppVisitcard),
    _createVNode(_component_AppContact, {
      currentSection: "contact",
      id: "contact",
      isLandingpage: true,
      title: "Wollen Sie mit Ihren Visitenkarten überzeugen?",
      callToAction: "Dann fordern Sie jetzt Ihr unverbindliches Angebot an und überzeugen Sie sich selbst von meiner Arbeit.",
      text: "Lassen Sie Ihre Visitenkarten von einem erfahrenen Designer gestalten. Ich biete Ihnen ein professionelles und hochwertiges Design, das zu Ihrem Unternehmen und Ihrer Zielgruppe passt.",
      subject: "Grafikdesign"
    })
  ], 64))
}