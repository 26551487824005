
import { defineComponent } from "vue";
import { smoothScrollTo } from "@/utilities/smoothScrollTo";

export default defineComponent({
  name: "AppServiceCard",
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    transitionClass: {
      type: String,
    },
  },
  computed: {
    //splits the text based on a comma and returns an array with the individual parts.
    convertedText(): string[] {
      return this.text.split(", ");
    },
    // returns the path to an image located in a directory named "icon" within the assets.
    imagePath(): string {
      return require(`@/assets/icon/${this.imageSrc}`);
    },
  },
  methods: {
    getLink(name: string): string {
      const umlauteMap: { [key: string]: string } = {
        ä: "ae",
        ö: "oe",
        ü: "ue",
        ß: "ss",
        Ä: "Ae",
        Ö: "Oe",
        Ü: "Ue",
      };
      const link = name
        .replace(/[äöüßÄÖÜ]/g, (match) => umlauteMap[match])
        .replace(/\s/g, "");
      return "/" + link;
    },

    scrollToSection(sectionId: string): void {
      const section = document.getElementById(sectionId);
      if (section) {
        smoothScrollTo(section);
      }
    },
  },
});
