import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppParallax = _resolveComponent("AppParallax")!
  const _component_AppServiceCard = _resolveComponent("AppServiceCard")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("section", { id: _ctx.id }, [
    _createVNode(_component_AppParallax, { title: _ctx.pageTitle }, null, 8, ["title"]),
    _createVNode(_component_v_container, { class: "my-15 py-15" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
              return (_openBlock(), _createBlock(_component_v_col, {
                key: card.title,
                cols: "12",
                md: "6",
                lg: "4",
                class: "my-15"
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_AppServiceCard, {
                    imageSrc: card.img,
                    title: card.title,
                    text: card.text,
                    transitionClass: card.transitionClass
                  }, null, 8, ["imageSrc", "title", "text", "transitionClass"]), [
                    [_vShow, _ctx.isIntersecting]
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 8, _hoisted_1))
}