
import { defineComponent } from "vue";
import { smoothScrollTo } from "@/utilities/smoothScrollTo";

export default defineComponent({
  name: "AppScrollFab",
  props: {
    currentSection: {
      type: String,
      required: true,
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    sectionIds() {
      const sections = document.querySelectorAll("section[id]");
      return Array.from(sections).map((section) => section.id);
    },
  },
  data() {
    return {
      nextSectionId: this.currentSection,
      icon: "mdi-arrow-down-bold-outline",
    };
  },
  watch: {
    currentSection: function (newSectionId) {
      const index = this.sectionIds.indexOf(newSectionId);
      const nextIndex = index + 1;
      if (nextIndex >= this.sectionIds.length) {
        this.nextSectionId = this.sectionIds[0];
        this.icon = "mdi-arrow-up-bold-outline";
      } else {
        this.nextSectionId = this.sectionIds[nextIndex];
        this.icon = "mdi-arrow-down-bold-outline";
      }
    },
  },
  methods: {
    scrollToSection() {
      const section = document.getElementById(this.nextSectionId);
      if (section) smoothScrollTo(section);
    },
  },
});
