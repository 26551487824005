import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppParallax = _resolveComponent("AppParallax")!
  const _component_App_carousel = _resolveComponent("App-carousel")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("section", { id: _ctx.id }, [
    _createVNode(_component_AppParallax, { title: _ctx.pageTitle }, null, 8, ["title"]),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createBlock(_component_App_carousel, {
            key: item.title,
            carouselItems: item.pictures,
            title: item.title,
            text: item.text,
            isIntersecting: _ctx.isIntersecting
          }, null, 8, ["carouselItems", "title", "text", "isIntersecting"]))
        }), 128))
      ]),
      _: 1
    })
  ], 8, _hoisted_1))
}