import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_headerback = _resolveComponent("app-headerback")!
  const _component_AppCoverdesign = _resolveComponent("AppCoverdesign")!
  const _component_AppContact = _resolveComponent("AppContact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_headerback),
    _createVNode(_component_AppCoverdesign),
    _createVNode(_component_AppContact, {
      currentSection: "contact",
      id: "contact",
      isLandingpage: true,
      title: "Willst du ein Coverdesign, das deine Leser begeistert?",
      callToAction: "Lass mich dir helfen!",
      text: "Ich bin ein professioneller Coverdesigner und helfe dir dabei, das perfekte Cover für dein Buch zu finden. Ich arbeite mit dir zusammen, um ein Design zu erstellen, das deinen Stil und deine Botschaft widerspiegelt. Fordere jetzt dein unverbindliches Angebot an und überzeuge dich selbst von meiner Arbeit!",
      subject: "Grafikdesign"
    })
  ], 64))
}