import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_headerback = _resolveComponent("app-headerback")!
  const _component_AppArtExhibition = _resolveComponent("AppArtExhibition")!
  const _component_App_carousel = _resolveComponent("App-carousel")!
  const _component_AppContact = _resolveComponent("AppContact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_headerback),
    _createVNode(_component_AppArtExhibition),
    (_openBlock(), _createBlock(_component_App_carousel, {
      key: _ctx.title,
      carouselItems: _ctx.pictures,
      title: _ctx.title,
      text: _ctx.text,
      isIntersecting: true
    }, null, 8, ["carouselItems", "title", "text"])),
    _createVNode(_component_AppContact, {
      currentSection: "contact",
      id: "contact",
      isLandingpage: true,
      title: "Wollen Sie Ihre Ausstellung mit meinen Kunstbildern bereichern?",
      callToAction: "Dann kontaktiere mich noch heute!",
      text: "Ich bin immer offen für neue Ideen und bin gerne bereit, mit Ihnen zusammenzuarbeiten.",
      subject: "Art"
    })
  ], 64))
}