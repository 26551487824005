
import { SocialmediaItem } from "@/types/SocialmediaItem";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isIntersecting: Boolean,
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
  },
  data() {
    return {
      socialmediaItems: [
        {
          label: "WhatsApp",
          link: "https://wa.me/4917662307783",
          icon: "mdi-whatsapp",
        },
        {
          label: "Email",
          link: "mailto:elena.ischtschenko@gmail.com",
          icon: "mdi-email",
        },
        {
          label: "Facebook",
          link: "https://www.facebook.com/ElenaElodiaArt/",
          icon: "mdi-facebook",
        },
        {
          label: "Telegram",
          link: "https://t.me/elena_elodia",
          icon: "mdi-telegram",
        },
        {
          label: "0176 - 623 077 83",
          link: "tel:+4917662307783",
          icon: "mdi-phone",
        },
        {
          label: "Instagram",
          link: "https://www.instagram.com/elena_elodia_art/",
          icon: "mdi-instagram",
        },
      ] as SocialmediaItem[],
    };
  },
});
