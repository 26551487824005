import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_app_start = _resolveComponent("app-start")!
  const _component_app_scroll_fab = _resolveComponent("app-scroll-fab")!
  const _component_app_about = _resolveComponent("app-about")!
  const _component_app_service = _resolveComponent("app-service")!
  const _component_app_portfolio = _resolveComponent("app-portfolio")!
  const _component_app_contact = _resolveComponent("app-contact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_header),
    _createVNode(_component_app_start, {
      currentSection: _ctx.currentSection,
      id: "home"
    }, null, 8, ["currentSection"]),
    _createVNode(_component_app_scroll_fab, { currentSection: _ctx.currentSection }, null, 8, ["currentSection"]),
    _createVNode(_component_app_about, {
      currentSection: _ctx.currentSection,
      id: "about"
    }, null, 8, ["currentSection"]),
    _createVNode(_component_app_service, {
      currentSection: _ctx.currentSection,
      id: "service"
    }, null, 8, ["currentSection"]),
    _createVNode(_component_app_portfolio, {
      currentSection: _ctx.currentSection,
      id: "portfolio"
    }, null, 8, ["currentSection"]),
    _createVNode(_component_app_contact, {
      currentSection: _ctx.currentSection,
      id: "contact"
    }, null, 8, ["currentSection"])
  ], 64))
}