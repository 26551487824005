import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_headerback = _resolveComponent("app-headerback")!
  const _component_AppBrochure = _resolveComponent("AppBrochure")!
  const _component_AppContact = _resolveComponent("AppContact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_headerback),
    _createVNode(_component_AppBrochure),
    _createVNode(_component_AppContact, {
      currentSection: "contact",
      id: "contact",
      isLandingpage: true,
      title: "Brauchen Sie eine Broschüre, die Ihre Kunden überzeugt?",
      callToAction: "Dann sind Sie bei mir genau richtig! Ich bin Grafikdesigner und ich helfe Ihnen dabei, eine Broschüre zu erstellen, die überzeugt.",
      text: "Ich bin mir sicher, dass ich Sie mit meiner Arbeit überzeugen kann. Fordern Sie jetzt ein kostenloses Angebot an!",
      subject: "Grafikdesign"
    })
  ], 64))
}