import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, {
    "no-gutters": "",
    class: "socialRow my-10"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialmediaItems, (button, index) => {
        return (_openBlock(), _createBlock(_component_v_col, {
          key: index,
          cols: "12",
          md: "6",
          lg: "4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              size: "x-large",
              variant: "plain",
              href: button.link,
              target: "_blank"
            }, {
              default: _withCtx(() => [
                _createVNode(_Transition, {
                  name: "bounce",
                  css: !_ctx.isMobile
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("div", {
                      class: _normalizeClass(["mb-15 pa-5", { socialBtn: false }])
                    }, [
                      _createVNode(_component_v_icon, {
                        start: "",
                        icon: button.icon
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(button.label), 1)
                    ], 512), [
                      [_vShow, _ctx.isIntersecting || _ctx.isMobile]
                    ])
                  ]),
                  _: 2
                }, 1032, ["css"])
              ]),
              _: 2
            }, 1032, ["href"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}