import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_headerback = _resolveComponent("app-headerback")!
  const _component_AppBuchillustration = _resolveComponent("AppBuchillustration")!
  const _component_AppContact = _resolveComponent("AppContact")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_headerback),
    _createVNode(_component_AppBuchillustration),
    _createVNode(_component_AppContact, {
      currentSection: "contact",
      id: "contact",
      isLandingpage: true,
      title: "Möchtest du dein Buch mit einzigartigen Illustrationen aufwerten?",
      callToAction: "Jetzt Kontakt aufnehmen und unverbindliches Angebot anfordern!",
      text: "Ich erstelle individuelle Buchillustrationen für Kinderbücher, Romane, Sachbücher und mehr. Meine Illustrationen sind detailliert und ausdrucksstark und passen perfekt zum Inhalt deines Buches.\n          Ich arbeite mit dir zusammen, um deine Vorstellungen zu verwirklichen.  Möchtest du mehr über meine Arbeit erfahren? Dann melde dich bei mir! Ich freue mich auf deine Anfrage.",
      subject: "Illustration"
    })
  ], 64))
}