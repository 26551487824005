
import { defineComponent } from "vue";
import { CarouselItem } from "@/types/CarouselItem";

export default defineComponent({
  name: "AppCarousel",
  props: {
    carouselItems: {
      type: Array as () => CarouselItem[],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    isIntersecting: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
  },
  methods: {
    /**
     * This function takes a string argument 'src' and returns the path to the corresponding image asset in the assets folder. It uses the 'require' function to dynamically load the image file.
     */
    imagePath(src: string): string {
      return require(`@/assets/${src}`);
    },
  },
});
